import React, { useRef, useEffect } from 'react';
import Pdf from "../images/BOGO For Retail.pdf"

const Retail = () => {

    return (
        <div className="App">

            <iframe width="100%" height="2100px" src={Pdf}></iframe>
        </div>


    );
};

export default Retail;